/* Mobile-first approach styling */
.app-layout {
  min-height: 100vh;
}

.app-layout-header {
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 12px !important;
}

.app-layout-header__left {
  display: flex;
  align-items: center;
}

.btn-hamburger {
  font-size: 15px;
  width: 45px;
  height: 45px;
  padding: 7px;
  margin-right: 15px;
  /* background-color: transparent; */
}
.hamburger__line {
  height: 4px;
  background-color: #ffffff;
  margin-top: 4px;
  margin-bottom: 4px;
}

.sider__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 200px;
}

.app-layout-header__title {
  display: flex;
  align-items: center;
}

.app-layout-header__title img {
  width: 250px;
}

.app-layout-header__title--link {
  display: flex;
  align-items: center;
}

.nav-items {
  color: #ffffff;
  font-size: 14px;
}

.nav-item__link {
  color: white !important;
  border: 1px solid white;
  border-radius: 5px;
  padding: 10px;
  margin-right: 5px;
  margin-left: 5px;
}

.nav-item__link:hover {
  opacity: 0.8;
  background-color: white;
  border: 1px solid #001529;
  color: #001529 !important;
  transition: all 0.5s ease-in-out;
}

/* .app-layout-header__left .nav-item__link {
  color: #ffffff;
  font-size: 16px;
} */

/* .app-layout-header__left .nav-item__link:hover {
  border: 1px solid azure;
  background-color: azure;
  color: #000;
} */

.app-layout-header__right {
  display: flex;
}

.app-layout-header__title {
  font-size: 17px;
  font-weight: bold;
  color: #ffffff;
}

.app-layout-content {
  padding: 0 15px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Desktop-size screen styling and above */
@media (min-width: 992px) {
  .app-layout-header {
    padding-inline: 50px !important;
  }

  .app-layout-header__title img {
    width: 200px;
  }
}

@media(min-width: 776px) {
  .app-layout-content {
    padding: 0 50px;
  }
}