/* Styles in this file are global. Meaning the styles apply to the whole project */

@font-face {
  font-family: "YogaSansProLight";   /*Can be any text*/
  src: local("YogaSansProLight"),
    url("./fonts/YogaSansProLight.otf") format("opentype");
}
@font-face {
  font-family: "YogaSansProThin";   /*Can be any text*/
  src: local("YogaSansProThin"),
    url("./fonts/YogaSansProThin.otf") format("opentype");
}
@font-face {
  font-family: "YogaSansProRegular";   /*Can be any text*/
  src: local("YogaSansProRegular"),
    url("./fonts/YogaSansPro.otf") format("opentype");
}

body {
  margin: 0;
  font-family: 'YogaSansProLight';
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-center {
  text-align: center;
}

.text-link {
  color: #65aaff;
  font-size: 17px;
}

.light-blue {
  color: #65aaff;
}

.text-wrap {
  /* word-break: break-all; */
  word-wrap: break-word;
  width: 100%;
}

.font-regular {
  font-family: 'YogaSansProRegular';
}

.font-light {
  font-family: 'YogaSansProLight';
}

.font-thin {
  font-family: 'YogaSansProThin';
}

/* Start: Form styles - check the @media query below for more customization based on screen ratio */
.form-item .ant-row {
  flex-direction: column;
}
.form-item .ant-col {
  flex: unset;
  text-align: start;
}

.form-item .ant-col .ant-form-item-explain-error {
  text-align: start;
}
/* End */

/* Desktop-size screen styling and above */
@media (min-width: 768px) {
  .form-item .ant-row {
      flex-direction: row;
  }

  .form-item .ant-col {
      flex: 1;
      text-align: end;
  }

  .form-item__submit .ant-col {
      text-align: start;
  }
}