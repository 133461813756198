.transaction-card-body .ant-card-body {
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 0;
}


.transaction-group .transaction-card-item .transaction-item-details {
    display: flex;
    justify-content: space-between;
}

.transaction-item-details .transaction-amount {
    font-weight: bold;
    font-size: 16px;
}

.transaction-card-body .ant-card {
    border-radius: 7px;
}

.transaction-card-body .transaction-date {
    padding: 0;
    margin: 7px 0;
    font-size: 18px;
    font-weight: bold;
}

.transaction-card-body .transaction-card-item {
    transition: background-color 0.3s ease-in-out;
}

.transaction-card-body .transaction-card-item:hover {
    background-color: aliceblue;
    color:rgba(0, 0, 0, 0.639);
    cursor: pointer;
}

.transaction-card-body .transaction-card-item .transaction-recipient {
    color: #49aa19;
    /* color: #32a852; */
}

@media(min-width: 768px) {
    .transaction-card-body .ant-card-body {
        padding-left: 24px;
        padding-right: 24px;
    }
}