.transaction-actions-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.transaction-actions-section .btn-transaction {
    margin: 0 0 8px 0;
}

@media(min-width: 768px) {
    .transaction-actions-section {
        flex-direction: row;
        justify-content: end;
    }

    .transaction-actions-section .btn-transaction {
        margin: 0 0 0 10px;
    }
}