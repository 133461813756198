/* Main container for support page */
.support-container {
    padding: 20px;
    font-family: Arial, Helvetica, sans-serif; /* Apply font-family globally */
}

.support-container p {
    font-size: 15px !important; /* Set font size to 15px */
    line-height: 1.6; /* Adjust line height for readability */
}

.support-container a {
    color: #65aaff !important; /* Use !important to ensure it overrides other styles */
    text-decoration: none; /* Optional: Remove underline */
}

/* Hover state */
.support-container a:hover {
    color: #4a90e2 !important; /* Optional: Change color on hover */
    text-decoration: underline; /* Optional: Underline on hover */
}

.text-center {
    text-align: center;
}

.support-image {
    width: 100%;
    max-width: 100%;
    border-radius: 8px;
}

.video-wrapper {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    margin-top: 20px;
    display: block;
    background-color: #000;
    border-radius: 8px;
}

.video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.ant-divider {
    margin: 40px 0;
}
.paragraph-custom {
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
  }
